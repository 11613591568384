import { FilterParam, SortConfig } from '@/domain/interface/certificaten/certifcaatTypes';

export function flattenSort(sort: SortConfig[]) {
  return sort.map((s) => `&sortBy=${s.property}&sortDir=${s.direction}`).join('');
}

export function addSearch(search: string) {
  return search ? `&search=${search}` : '';
}

export function addFilters(filters: FilterParam[]) {
  return filters
    .map((filter) => `&filter=${filter.field}&values=${filter.values.join(encodeURIComponent('|'))}`)
    .join('');
}
