import Vue from 'vue';
import Vuex from 'vuex';

import Gebruiker from '@/store/modules/gebruiker';
import Opdracht from '@/store/modules/opdracht';
import Enums from '@/store/modules/enums';
import Loader from '@/store/modules/loader';
import Errors from '@/store/modules/errors';
import Energiestroom from '@/store/modules/energiestroom';
import SuccessAlertMessages from '@/store/modules/successAlertMessages';
import WarningAlertMessages from "@/store/modules/warningAlertMessages";
import CopyPasteStore from '@/store/modules/kopieren/CopyPasteStore';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    packageVersion: process.env.PACKAGE_VERSION || '0',
  },
  actions: {},
  modules: {
    Opdracht,
    Gebruiker,
    Loader,
    Enums,
    Energiestroom,
    Errors,
    SuccessAlertMessages,
    CopyPasteStore,
    WarningAlertMessages,
  },
  getters: {
    appVersion: (state) => state.packageVersion,
  },
});
