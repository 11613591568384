import axios, {AxiosRequestConfig} from 'axios';
import NotificationService from '@/services/NotificationService';

export default class HttpService {
    constructor(private notificationService: NotificationService) {}

  get<T>(path: string, config?: unknown): Promise<T> {
    return axios.get(path, config).then(
      (result) => result.data,
      (error) => {
        this.notificationService.showMultipleErrors(error.response.data?.errors);
        throw error;
      }
    );
  }

  getFile(filename: string, path: string, config?: AxiosRequestConfig): Promise<void> {
    return axios
      .get(path, {
        responseType: 'arraybuffer',
        ...config,
      })
      .then((response) => {
        const type = response.headers['content-type'] + ';charset=utf-8';
        const blobUrl = window.URL.createObjectURL(
          new Blob([response.data], {
            type: type,
          })
        );
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = filename;
        a.click();
        a.remove();
        setTimeout(() => window.URL.revokeObjectURL(blobUrl), 100);
      })
      .catch((error) => {
        this.notificationService.showMultipleErrors(error.response.data?.errors);
        throw error;
      });
  }

  post<T>(path: string, requestBody: unknown, config?: unknown): Promise<T> {
    return axios.post(path, requestBody, config).then(
      (result) => result.data,
      (error) => {
        this.notificationService.showMultipleErrors(error.response.data?.errors);
        throw error;
      }
    );
  }

  put<T>(path: string, requestBody: unknown): Promise<T> {
    return axios.put(path, requestBody).then(
      (result) => result.data,
      (error) => {
        this.notificationService.showMultipleErrors(error.response.data?.errors);
        throw error;
      }
    );
  }

  delete(path: string): Promise<void> {
    return axios.delete(path).then(
      () => Promise.resolve(),
      (error) => {
        this.notificationService.showMultipleErrors(error.response.data?.errors);
        throw error;
      }
    );
  }
}
