export class OpdrachtSearchParams {
  searchString?: string;
  energiedeskundigen?: string;
  opdrachtStatussen?: string;

  constructor() {
    this.searchString = undefined;
    this.energiedeskundigen = undefined;
    this.opdrachtStatussen = undefined;
  }
}
