













import {Component, Prop} from 'vue-property-decorator';
import OpdrachtFilterInput from '@/views/partials/filter/OpdrachtFilterInput.vue';
import {InputOptionInterface} from '@/domain/interface/form/InputOptionInterface';
import {EnumInterface} from '@/domain/interface/intern/enumvalues/EnumInterface';
import {fetchIndependentEnum, mapForInput} from '@/util/enum/enumUtils';

@Component({
  components: { OpdrachtFilterInput },
})
export default class StatusOpdrachtFilterInput extends OpdrachtFilterInput {
  @Prop(Array) readonly value!: EnumInterface[];
  defaultValue: EnumInterface[] = [];
  items: InputOptionInterface[] = [];

  created() {
    this.loadOpdrachtStatussen();
  }

  loadOpdrachtStatussen() {
    fetchIndependentEnum('OpdrachtStatus', ['VEROUDERD']).then((items) => {
      this.items = mapForInput(items);
    });
  }

  onInput(value?: InputOptionInterface[]) {
    this.$emit('input', value);
    this.search();
  }

  search() {
    this.$nextTick(() => {
      this.$emit('search');
    });
  }
}
