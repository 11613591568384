


































































import SubtitleCollapsibleWithAdd from '@/components/title/SubtitleCollapsibleWithAdd.vue';
import { Component, Inject, Provide } from 'vue-property-decorator';
import OpdrachtCard from '@/components/card/OpdrachtCard.vue';
import { OpdrachtClass } from '@/domain/interface/opdracht/OpdrachtClass';
import { MenuItemInterface } from '@/domain/interface/intern/MenuItemInterface';
import { mixins } from 'vue-class-component';
import RulesMixin from '@/mixins/RulesMixin';
import AuthService from '@/services/AuthorizationService';
import { LocaleMessage } from 'vue-i18n';
import { namespace } from 'vuex-class';
import PrimaryButton from '@/components/button/PrimaryButton.vue';
import { Paginated } from '@/domain/interface/Paginated';
import Pagination from '@/components/layout/Pagination.vue';
import EnumConstantsMixin from '@/mixins/EnumConstantsMixin';
import OpdrachtFilter from '@/views/partials/filter/OpdrachtFilter.vue';
import { OpdrachtSearchParams } from '@/domain/interface/intern/OpdrachtSearchParams';
import ConfigHelper from '@/util/ConfigHelper';
import OpdrachtService from '@/services/OpdrachtService';

const Loader = namespace('Loader');
const Opdracht = namespace('Opdracht');

const auth = new AuthService();

@Component({
  components: {
    PrimaryButton,
    Pagination,
    OpdrachtCard,
    OpdrachtFilter,
    SubtitleCollapsibleWithAdd,
  },
})
export default class Home extends mixins(RulesMixin, EnumConstantsMixin) {
  @Provide() hasAccess = true;
  @Provide() loading = false;
  @Provide() opdrachten: OpdrachtClass[] = [];
  page = 1;
  opdrachtenCount = 0;
  opdrachtenPerPage = 8;
  searchParams = new OpdrachtSearchParams();
  @Inject('opdrachtService') opdrachtService: OpdrachtService;

  created() {
    if (process.env.NODE_ENV != 'development') {
      auth.isLoggedIn().then((loggedIn) => {
        if (!loggedIn) {
          auth.login();
        }
      });
    }

    this.updateLoaderLoading('');
    auth
      .hasAccess()
      .then((resp) => {
        this.hasAccess = resp;
      })
      .catch(() => {
        this.hasAccess = false;
      })
      .finally(this.updateLoaderDone);

    this.resetOpdracht();
  }

  navToCreateOpdracht(): void {
    this.$router.push({ name: 'opdrachtAanmaken' });
  }

  navToOpdracht(uuid: string): void {
    this.$router.push({ name: 'opdracht', params: { opdrachtUuid: uuid } });
  }

  clickOpdrachtItem(item: MenuItemInterface): void {
    this.$router.push(item.to);
  }

  public loadOpdrachten() {
    this.loading = true;
    this.opdrachten = [];
    const pageParams = { limit: this.opdrachtenPerPage, offset: this.page - 1 };

    return this.opdrachtService
      .getOpdrachten(pageParams, this.searchParams)
      .then((page: Paginated<OpdrachtClass>) => {
        this.opdrachtenCount = page.count;
        page.data.forEach((opdracht) => {
          this.opdrachten.push(new OpdrachtClass(opdracht));
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  public search(params: OpdrachtSearchParams) {
    this.loading = true;
    this.updateLoaderLoading('We zoeken...');
    this.searchParams = params;
    this.page = 1;
    this.loadOpdrachten().finally(() => {
      this.updateLoaderDone();
    });
  }

  get notInRolErrorMessage() {
    const toepassingsnaam = ConfigHelper.isLeeromgeving()
      ? this.$t('app.leeromgeving')
      : this.$t('app.title');

    return this.$t('errors.geen-toegang', {
      rol: this.rolOmschrijving,
      toepassingsnaam: toepassingsnaam,
    });
  }

  get rolOmschrijving() {
    if (ConfigHelper.isRolDeskundigeD()) {
      return this.$t('rol.energiedeskundige-type-d');
    } else if (ConfigHelper.isRolStudentTypeD()) {
      return this.$t('rol.student-type-d');
    } else {
      return '';
    }
  }

  get epbUrl() {
    return process.env.VUE_APP_EPB_URL;
  }

  @Loader.Action
  public updateLoaderLoading!: (message?: string | LocaleMessage) => void;

  @Loader.Action
  public updateLoaderDone!: () => void;

  @Opdracht.Action
  public resetOpdracht!: () => void;
}
