var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.numberInput)?_c('v-autocomplete',{attrs:{"value":_vm.value,"items":_vm.items,"rules":_vm.rules,"loading":_vm.loading,"filter":_vm.filterObject,"id":_vm.id,"label":_vm.label,"item-text":_vm.itemText,"disabled":_vm.isReadOnly || _vm.disabled,"multiple":!!_vm.multiple,"no-filter":_vm.noFilter},on:{"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([(_vm.hasItemsSlot())?{key:"item",fn:function(ref){
var item = ref.item;
return [(_vm.hasItemsSlot())?_vm._t("item",null,{"item":{ item: item }}):_vm._e()]}}:null,(_vm.hasSelectionSlot())?{key:"selection",fn:function(ref){
var item = ref.item;
return [(_vm.hasSelectionSlot())?_vm._t("selection",null,{"selection":{ item: item }}):_vm._e()]}}:null],null,true)}):_c('v-autocomplete',{attrs:{"value":_vm.value,"items":_vm.items,"rules":_vm.rules,"loading":_vm.loading,"filter":_vm.filterObject,"id":_vm.id,"label":_vm.label,"disabled":_vm.isReadOnly || _vm.disabled,"search-input":_vm.input,"item-text":_vm.itemText,"multiple":!!_vm.multiple,"no-filter":_vm.noFilter},on:{"input":function($event){return _vm.$emit('input', $event)},"update:searchInput":function($event){_vm.input=$event},"update:search-input":function($event){_vm.input=$event}},scopedSlots:_vm._u([(_vm.hasItemsSlot())?{key:"item",fn:function(ref){
var item = ref.item;
return [(_vm.hasItemsSlot())?_vm._t("item",null,{"item":{ item: item }}):_vm._e()]}}:null,(_vm.hasSelectionSlot())?{key:"selection",fn:function(ref){
var item = ref.item;
return [(_vm.hasSelectionSlot())?_vm._t("selection",null,{"selection":{ item: item }}):_vm._e()]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }