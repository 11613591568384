import HttpService from '@/services/HttpService';
import {OpdrachtClass} from '@/domain/interface/opdracht/OpdrachtClass';
import {OpdrachtRequestDTO} from '@/domain/interface/opdracht/OpdrachtRequestDTO';
import {Paginated} from "@/domain/interface/Paginated";

export default class OpdrachtService {
  private readonly PATH = '/opdrachten';

  constructor(public http: HttpService) {}

  getOpdracht(id: string): Promise<OpdrachtClass> {
    return this.http.get<OpdrachtClass>(`${(this.PATH)}/${id}`);
  }

  updateOpdracht(link: string, requestBody: OpdrachtRequestDTO) {
    return this.http.put<OpdrachtClass>(link, requestBody);
  }

  createOpdracht(requestBody: OpdrachtRequestDTO) {
    return this.http.post<OpdrachtClass>(this.PATH, requestBody);
  }

  dupliceerOpdracht(link: string, requestBody: OpdrachtRequestDTO) {
    return this.http.post<OpdrachtClass>(link, requestBody);
  }

  deleteOpdracht(link: string) {
    return this.http.delete(link);
  }

  getOpdrachten(pageParams: object, searchParams: object): Promise<Paginated<OpdrachtClass>> {
    return this.http.get<Paginated<OpdrachtClass>>(this.PATH, {params: {...pageParams, ...searchParams}});
  }

}
