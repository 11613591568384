























































import {Component, Prop} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import RulesMixin from '@/mixins/RulesMixin';
import EnumConstantsMixin from '@/mixins/EnumConstantsMixin';
import SearchInput from '@/components/form/SearchInput.vue';
import MenuButtonCheckbox from '@/components/form/MenuButtonCheckbox.vue';
import AutocompleteInput from '@/components/form/AutocompleteInput.vue';
import CombinedInputWithIconButton from '@/components/form/CombinedInputWithIconButton.vue';
import OpdrachtFilterInput from '@/views/partials/filter/OpdrachtFilterInput.vue';
import EnergiedeskundigeFilterInput from '@/views/partials/filter/filterInputs/EnergiedeskundigeFilterInput.vue';
import {EnumInterface} from '@/domain/interface/intern/enumvalues/EnumInterface';
import {namespace} from 'vuex-class';
import {OpdrachtSearchParams} from '@/domain/interface/intern/OpdrachtSearchParams';
import StatusOpdrachtFilterInput from '@/views/partials/filter/filterInputs/StatusOpdrachtFilterInput.vue';
import ConfigHelper from '@/util/ConfigHelper';

const Gebruiker = namespace('Gebruiker');

@Component({
  components: {
    StatusOpdrachtFilterInput,
    EnergiedeskundigeFilterInput,
    OpdrachtFilterInput,
    CombinedInputWithIconButton,
    AutocompleteInput,
    MenuButtonCheckbox,
    SearchInput,
  },
})
export default class OpdrachtFilter extends mixins(
    RulesMixin,
    EnumConstantsMixin
) {
  searchValue = '';

  sortAscending = true;
  listView = false;
  filterItems: string[] = [];
  energiedeskundigeFilter: EnumInterface[] = [];
  opdrachtStatusFilter: EnumInterface[] = [];
  isLeeromgeving = ConfigHelper.isLeeromgeving();
  @Prop({default:false}) isImport!: boolean;
  @Prop({default:false}) inDialog!: boolean;

  public initialLoad(value: EnumInterface[]) {
    this.energiedeskundigeFilter = value;
    this.search();
  }

  public search() {
    this.$emit('search', this.getSearchParams());
  }

  private getSearchParams(): OpdrachtSearchParams {
    const searchParams = new OpdrachtSearchParams();
    if (this.searchValue) {
      searchParams.searchString = this.searchValue;
    }
    if (this.energiedeskundigeFilter) {
      searchParams.energiedeskundigen = this.energiedeskundigeFilter
          .map((value) => value.code)
          .join(',');
    }
    if (this.opdrachtStatusFilter) {
      searchParams.opdrachtStatussen = this.opdrachtStatusFilter
          .map((value) => value.code)
          .join(',');
    }
    return searchParams;
  }

  goToCertificaten() {
    this.$router.push({
      name: 'certificaten',
    });
  }

  get menuItems() {
    const menuItems = [];
    menuItems.push({
      label: this.$t('opdrachten.filter.energiedeskundige'),
      value: this.$code.opdrachtFilter.ENERGIEDESKUNDIGE,
    });
    menuItems.push({
      label: this.$t('opdrachten.filter.status'),
      value: this.$code.opdrachtFilter.STATUS,
    });
    return menuItems;
  }

  @Gebruiker.Getter
  public isControleur!: boolean;
}
