













import {Component, Inject, Prop, Watch} from 'vue-property-decorator';
import {getEnumFromCode, mapForInput} from '@/util/enum/enumUtils';
import OpdrachtFilterInput from '@/views/partials/filter/OpdrachtFilterInput.vue';
import {InputOptionInterface} from '@/domain/interface/form/InputOptionInterface';
import {namespace} from 'vuex-class';
import {EnumInterface} from '@/domain/interface/intern/enumvalues/EnumInterface';
import GebruikerService from '@/services/GebruikerService';

const Gebruiker = namespace('Gebruiker');

@Component({
  components: { OpdrachtFilterInput },
})
export default class EnergiedeskundigeFilterInput extends OpdrachtFilterInput {
  @Inject('gebruikerService') gebruikerService: GebruikerService;
  readonly value: EnumInterface[] = [];
  @Prop({default: true}) readonly shouldSelectCurrentUser!: boolean;
  defaultValue: EnumInterface[] = [];

  items: InputOptionInterface[] = [];

  created() {
    this.loadEnergiedeskundigen();
  }

  //De energiedeskundigen worden pas ingeladen wanneer de huidige gebruiker geladen is.
  //Dit is nodig om de huidige gebruiker als default te selecteren.
  //Dat is de reden dat deze functie zowel:
  //   -in de created staat (Voor het geval de gebruiker al ingeladen was)
  //   -bij een watch staat (Voor het geval de gebruiker nog niet ingeladen was)
  @Watch('erkenningsnummer')
  loadEnergiedeskundigen() {
    if (this.erkenningsnummer) {
      this.gebruikerService.getGebruikers().then(( data ) => {
        const currentUser = getEnumFromCode(data, this.erkenningsnummer);
        if (this.shouldSelectCurrentUser && currentUser) {
          this.value.push(currentUser);
          this.defaultValue.push(currentUser);
        }
        this.items = mapForInput(data).sort(this.userComparator());
        this.$emit('initial-load', this.value);
      });
    }
  }

  @Watch('filterItems')
  public emitChangeWhenFilterOpensOrCloses(newVal: string[], oldVal: string[]) {
    if (oldVal.includes('ENERGIEDESKUNDIGE') && !newVal.includes('ENERGIEDESKUNDIGE') || !oldVal.includes('ENERGIEDESKUNDIGE') && newVal.includes('ENERGIEDESKUNDIGE')) {
      this.$emit('filter-visibility-changed','ENERGIEDESKUNDIGE', this.value);
    }
  }

  onInput(value?: InputOptionInterface[]) {
    this.$emit('input', value);
    this.$emit('filter-changed');
    this.search();
  }

  search() {
    this.$nextTick(() => {
      this.$emit('search');
    });
  }

  private userComparator() {
    //Comparator om de option met het erkenningsnummer van de gebruiker op de eerste plaats te zetten.
    return (user: InputOptionInterface) =>
      user.value.code === this.erkenningsnummer ? -1 : 0;
  }

  @Gebruiker.Getter
  public erkenningsnummer!: string;
}
