import { CertificaatFilter } from '@/domain/interface/certificaten/certifcaatTypes';
import HttpService from '@/services/HttpService';
import { addFilters, addSearch, flattenSort } from '@/util/filters/filterUtil';

export default class DownloadService {
  private readonly PATH = '/certificaten/download-excel';

  constructor(public http: HttpService) {}

  public downloadFile(url: string, filename: string): Promise<void> {
    return this.http.getFile(filename, url);
  }

  public downloadExcel(filename: string, filter: CertificaatFilter): Promise<void> {
    return this.http.getFile(
      filename,
      `${this.PATH}?page=${filter.page}&size=${filter.size}${flattenSort(filter.sort)}${addSearch(
        filter.searchString
      )}${addFilters(filter.filters)}`
    );
  }
}
