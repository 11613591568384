













import {Component, Prop} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import RulesMixin from '@/mixins/RulesMixin';
import EnumConstantsMixin from '@/mixins/EnumConstantsMixin';
import SearchInput from '@/components/form/SearchInput.vue';
import MenuButtonCheckbox from '@/components/form/MenuButtonCheckbox.vue';
import AutocompleteInput from '@/components/form/AutocompleteInput.vue';
import CombinedInputWithIconButton from '@/components/form/CombinedInputWithIconButton.vue';
import TextInput from '@/components/form/TextInput.vue';

/*
 * Dit is een basic-filter-input-component.
 * Deze bevat de basisfunctionaliteit om tevoorschijn te komen wanneer bijhorende filtering geselecteerd wordt.
 * Hier zit de logica om het veld weg te halen bij het drukken op een kruisje
 * Bevat een slot om een input naar keuze in te plaatsen.
 *
 * Best dit component niet zo gebruiken, maar overerven zoals bij "EnergiedeskundigeFilterInput.vue".
 * */
@Component({
  components: {
    TextInput,
    CombinedInputWithIconButton,
    AutocompleteInput,
    MenuButtonCheckbox,
    SearchInput,
  },
})
export default class OpdrachtFilterInput extends mixins(
  RulesMixin,
  EnumConstantsMixin
) {
  @Prop(Array) readonly filterItems!: string[];
  @Prop(String) readonly enumValue!: string;
  @Prop({default: false}) inDialog;

  get isInItemList() {
    return !!this.filterItems.find((item) => item === this.enumValue);
  }

  removeFromItemList() {
    this.filterItems.splice(
      this.filterItems.findIndex((item) => item === this.enumValue),
      1
    );
  }
}
